<template>
  <b-card class="w-50 m-auto text-center">
    <b-overlay :show="isProcessing">
      <div class="d-flex justify-content-center align-items-center">
        <feather-icon
          icon="FrownIcon"
          size="48"
          color="#ff9f43"
        />
        <h3 class="ml-1">
          Your deposit has been cancelled!
        </h3>
      </div>
      <b-button
        class="mt-4"
        variant="primary"
        @click="gotoWallets"
      >
        Go back to your accounts
      </b-button>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BButton, BOverlay } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BButton,
    BOverlay,
  },
  data() {
    return {
      isProcessing: false,
    }
  },
  methods: {
    gotoWallets() {
      this.isProcessing = true
      this.$router.push({ name: 'wallet' })
    },
  },
}
</script>
